@import 'src/theme/theme';

.hk-products {
  position: relative;
  &-breadcrumbs {
    position: absolute;
    top: -36px;
    font-weight: bold;
  }
  &-total {
    position: absolute;
    top: -36px;
    right: 0;
    font-size: 14px;
    font-weight: bold;
    border-radius: 8px;
    padding: 9px 16px;
    border: solid 1px $scotty;
  }
  &-header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    justify-items: stretch;
    &-title {
      font-size: 15px;
      p {
        margin-bottom: 0;
        font-weight: bold;
      }
    }
    &-copy-button {
      border: 1px solid $romulan !important;
      height: 48px !important;
      margin-left: 16px;
      font-size: 14px;
      font-weight: bold !important;
      color: $romulan;
      background: #fff !important;
      border-radius: 8px !important;
      box-shadow: 0 6px 20px 0 rgba(13, 51, 32, 0.1);
      &:hover {
        color: #fff !important;
        box-shadow: none;
        background: $klingon !important;
      }
    }
  }
  &-list-image-preview {
    opacity: 0.2;
  }
  .ant-table-row.hk-table-row {
    .ant-table-cell:first-child {
      padding: 12px 16px !important;
    }
    cursor: pointer;
    .hk-products-status {
      display: flex;
      align-items: center;
      white-space: nowrap;
      font-weight: bold;
      font-size: 14px;
      .status-circle {
        height: 10px;
        width: 10px;
        border-radius: 50%;
        margin-right: 4px;
        &.completed {
          background-color: $enterprise;
        }
        &.new-home {
          background-color: $kirk;
        }
        &.new-product {
          background-color: $uhura;
        }
      }
    }
    .hk-products-actions {
      display: flex;
      align-items: center;
    }
    button.hk-small-button {
      height: 40px;
      width: 48px;
      background: $vulcan !important;
      margin-right: 8px;
      &:hover {
        color: #fff;
        background: $enterprise !important;
      }
      &.disabled {
        cursor: not-allowed;
        color: $vulcan;
        background: #e7e7e7 !important;
      }
    }
  }
}
