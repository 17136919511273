@import 'src/theme/theme';

.hk-contact-popover {
  font-family: "Open Sans", sans-serif;

  border-radius: 8px!important;
  .ant-popover-inner {
    border-radius: 8px;
    &-content {
      padding: 0;
      .ant-card {
        border-radius: 8px;
        &-head {
          border-radius: 8px 8px 0 0;
          border-bottom: none;
          font-size: 18px;
          font-weight: bold;
          color: #fff;
          background-color: $scotty;
        }
        &-body {
          font-size: 16px;
          border-radius:0 0 8px 8px;
          .ant-card-grid {
            border: 0!important;
            box-shadow: none;
            height: 40px!important;
            padding: 8px 0!important;
          }
          .hk-popover-card-label {
            width: 25%;
            text-align: left;
            font-size: 16px;
            color: $romulan;
            font-weight: bold;
          }
          .hk-popover-card-value {
            width: 75%;
            text-align: left;
            color: $voyager;
          }
        }
      }
    }
  }
  &-link {
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    text-decoration: underline;
    color: $scotty;
    &:hover { color: $enterprise; }
  }
}
