@import 'src/theme/theme';

.hk-asset-list {
  &-header {
    width: 100%;
    display: flex;
    align-items: center;
    font-weight: bold;
    margin-bottom: 16px;
    h4 { color: $klingon; margin-right: 8px; margin-bottom: 0; }
    button.ant-btn-icon-only,
    button.ant-btn-icon-only:focus {
      margin-bottom: 8px;
      color: #fff;
      background-color: $enterprise !important;
    }
  }
  &-items {
    display: flex;
    width: 100%;
    overflow: auto;
    &-item {
      min-width: 100px;
      min-height: 100px;
      margin-right: 16px;

      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      .ant-image {
        img {
          border-radius: 8px;
        }
      }
      &-media-icon {
        height: 100px;
        width: 100px;
        cursor: pointer;
        color: $vulcan;
        background-color: #fff;
        border: 2px solid $vulcan;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        .anticon {
          font-size: 48px;
        }
      }
      &-loading .anticon {
        margin: 8px 4px;
      }
      &.placeholder {
        display: flex;
        justify-content: space-around;
        padding-bottom: 50px;
      }
      &-actions {
        button.ant-btn {
          background-color: $romulan!important;
          color: #fff!important;
          &:hover {
            color: $enterprise!important;
            background-color: #fff!important;
          }
          &:disabled { background-color: $vulcan; }
        }
        .hk-asset-list-items-item-favorite,
        .hk-asset-list-items-item-remove {
          margin: 8px 4px;
        }
      }
    }
  }
  &-add {
    border-top: 1px solid $vulcan;
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    padding-top: 24px;
    &-title {
      padding: 16px 0;
      border-top: 1px solid $voyager;
    }
    &-header {
      h3 { margin-right: 12px; }
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      &-label {
        display: flex;
        flex-grow: 1;
        align-items: center;
        margin-left: 32px;
        input { margin-left: 16px; }
      }
      &-type {
        .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
          &::before { width: 0; padding: 0; }
          border-color: $enterprise!important;
          background-color: $enterprise!important;
          color: #fff;
          flex-wrap: nowrap;
        }
        .ant-radio-button-wrapper {
          &:first-child { border-top-left-radius: 8px; border-bottom-left-radius: 8px;}
          &:last-child { border-top-right-radius: 8px; border-bottom-right-radius: 8px;}
          &::before { width: 0; padding: 0; }
          background-color: #fff!important;
          line-height: 42px!important;
          height: 44px!important;
          &:hover { color: $enterprise; }
        }
      }
    }
  }
  &-error {
    margin-top: 16px;
    color: $uhura;
  }
}

.hk-video-modal {
  .ant-modal-content { background-color: transparent; }
  .player-wrapper {
    position: relative;
    height: 100%;
    width: 100%;
    padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
    overflow: hidden;
    background-color: transparent;
  }
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
    background-color: transparent;
  }
}

.copy-product-button {
  border: 0 !important;
  height: 36px!important;
  padding: 6px 16px;
  font-weight: bold;
  color: #fff;
  background: $enterprise!important;
  border-radius: 8px!important;
  box-shadow: 0 6px 20px 0 rgba(13,51,32,0.1);
  margin-left: 16px;
  &:hover {
    box-shadow: none;
    background: tint($enterprise, 30)!important;
  }
}
