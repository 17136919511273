@import 'src/theme/theme';

.hk-status-tags {
  font-family: "Open Sans", sans-serif;
  padding: 24px 0;
  border-bottom: 1px solid $klingon;
  //margin: 16px 0;

  &-list {
    .hk-status-tag-button {
      font-size: 16px;
      font-weight: bold;
      color: $klingon;
      background-color: #fff;
      line-height: 18px;
      height: 40px;
      padding: 8px 32px;
      border: 1px solid $klingon;
      border-radius: 20px;
      margin-right: 16px;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
      &:focus,
      &:hover { color: $klingon; border-color: $klingon }
      &.level-0:hover  { background-color: $dvana; }
      &.level-1:hover  { background-color: $rutherford; }
      &.level-2:hover  { background-color: $boimler; }
      &.level-0.selected { background-color: $tendi; border-color: $tendi; }
      &.level-1.selected { background-color: $kirk; border-color: $kirk; }
      &.level-2.selected { background-color: $uhura; border-color: $uhura; }
    }
  }
}
