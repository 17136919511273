@import 'src/theme/theme';

.hk-product-detail {
  position: relative;
  &-breadcrumbs {
    position: absolute;
    top: -36px;
    font-weight: bold;
  }
  &-header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    justify-items: stretch;
    margin-bottom: 40px;
    &-title {
      font-size: 15px;
      p {
        margin-bottom: 0;
        font-weight: bold;
      }
    }
    &-copy-button {
      border: 1px solid $romulan !important;
      height: 48px !important;
      margin-left: 16px;
      font-size: 14px;
      font-weight: bold !important;
      color: $romulan;
      background: #fff !important;
      border-radius: 8px !important;
      box-shadow: 0 6px 20px 0 rgba(13, 51, 32, 0.1);
      &:hover {
        color: #fff !important;
        box-shadow: none;
        background: $klingon !important;
      }
    }
  }
  .hk-form {
    &-status-attributes-loading {
      color: $scotty;
      .ant-spin {
        display: flex;
        font-size: 16px;
        color: $scotty;
        &-dot {
          color: $scotty !important;
          margin-right: 12px;
        }
      }
    }
  }
}

.hk-header-button-label {
  padding: 16px 24px;
  cursor: pointer;
}
