@import 'src/theme/theme';

.hk-admin-form {
  .ant-form-item-label {
    font-weight: bold;
  }
  .ant-select-arrow {
    &-loading {
      color: $scotty;
    }
    width: 20px;
    height: 20px;
    margin-top: -10px;
    svg {
      top: 100%;
      width: 20px;
      height: 20px;
    }
  }
}
