
.hk-layout {
  display: flex;
  height: 100vh;

  .hk-side-menu {
    flex: 0 0 300px;
    height: 100vh;
  }

  .hk-layout-content {
    padding: 60px;
    flex: 1;
    overflow: auto;
  }
}