
.hk-model-detail {
  position: relative;
  &-breadcrumbs {
    position: absolute;
    top: -36px;
    font-weight: bold;
  }
  h1 {
    margin-bottom: 64px!important;
  }
  form {
    margin: 32px;
  }
  .ant-form-item {
    margin: 16px 0;
  }

}
