@import 'src/theme/theme';

.hk-models {
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    justify-items: stretch;

    .ant-btn {
      height: 40px;
    }
  }
  &-list-image-preview {
    opacity: 0.2;
  }
  .ant-table-row.hk-table-row {
    cursor: pointer;
  }
}
