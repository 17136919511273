@import 'src/theme/theme';

.hk-side-menu {
  //position: fixed;
  //width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $discovery;
  color: #fff;
  &-links-logo {
    display: block;
    max-width: 160px;
    margin: 40px auto;
  }
  &-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
    border-top: 1px solid $scotty;
    h3 {
      color: #fff;
      font-weight: bold;
      &:hover {
        color: $enterprise;
        cursor: pointer;
      }
    }
  }
  &-version {
    font-style: italic;
    color: $vulcan;
  }
}

.hk-side-menu a {
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  display: block;
  padding: 16px 24px;
  &.active {
    background-color: $scotty;
  }
}

.hk-side-menu a:hover {
  cursor: pointer;
  color: $enterprise;
  //background-color: tint($enterprise, 80);
}
