@import '../../theme/theme';

.hk-task {
  &.level-0 { border-left: 4px solid $tendi; }
  &.level-1 { border-left: 4px solid $kirk; }
  &.level-2 { border-left: 4px solid $uhura; }

  background-color: #fff;
  padding: 24px;
  margin-bottom: 32px;
  border: 1px solid $vulcan;
  border-radius: 10px;
  &-header {
    h2 { text-transform: none!important; }
    &-actions {
      flex: 0 0 400px;
      text-align: right;
      &-finished {
        color: $voyager;
        font-weight: normal;
        font-style: italic;
        font-size: 16px;
        margin-bottom: 16px;
      }
      &-completed {
        font-size: 18px;
        margin-right: 24px;
        button { margin-left: 8px; }
      }
    }
  }
  &-header, &-notes-header, &-images-header {
    display: flex;
    font-weight: bold;
    justify-content: space-between;
    h4 { color: $scotty; }
    button.ant-btn-icon-only,
    button.ant-btn-icon-only:focus {
      border: none;
      color: #fff;
      background-color: $enterprise;
    }
    button.ant-btn-icon-only:hover {
      border: none;
      color: #fff;
      background-color: $sulu;
    }
  }
  &-notes {
    border-bottom: 1px solid $vulcan;
    padding: 8px 0;
    margin: 16px 0;
    &-note {
      color: $klingon;
      font-style: italic;
    }
    &-textarea {
      margin: 8px 0!important;
    }
  }
  .hk-image-list {
    &-header {
      justify-content: space-between;
    }
  }
}
