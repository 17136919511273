@import 'src/theme/theme';

.hk-home {
  .ant-table-row.hk-table-row {
    .hk-catalog-status {
      display: flex;
      align-items: center;
      white-space: nowrap;
      font-weight: bold;
      font-size: 14px;
      .status-circle {
        height: 10px;
        width: 10px;
        border-radius: 50%;
        margin-right: 4px;
        &.completed {
          background-color: $enterprise;
        }
        &.new-home {
          background-color: $kirk;
        }
        &.new-product {
          background-color: $uhura;
        }
      }
    }
    .hk-catalog-homeowner {
      white-space: nowrap;
      &-list {
        color: $enterprise;
      }
    }
    .hk-catalog-actions {
      display: flex;
      align-items: center;
    }
    button.hk-small-button {
      height: 40px;
      width: 48px;
      background: $vulcan !important;
      margin-right: 8px;
      &:hover {
        color: #fff;
        background: $enterprise !important;
      }
      &.disabled {
        cursor: not-allowed;
        color: $vulcan;
        background: #e7e7e7 !important;
      }
    }
  }
}
