@import 'src/theme/theme';

.hk-appointment {
  position: relative;
  &-breadcrumbs {
    position: absolute;
    top: -36px;
    font-weight: bold;
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: bold;
  }
  h1 {
    font-size: 24px;
    font-weight: bold;
    color: $romulan;
  }
  h4 {
    font-size: 16px;
    color: $klingon;
  }
  &-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    &-title {
      &-checkout-status {
        font-size: 18px;
        font-style: italic;
        color: $uhura;
      }
    }
    &-actions {
      button {
        margin-right: 16px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  &-details-info {
    font-size: 16px;
    color: $voyager !important;
    display: flex;
    justify-content: space-between;
    padding: 12px 0 24px 0;
    border-bottom: 1px solid $klingon;
    &-homeowner {
    }
    &-dates {
      display: flex;
      &-completed,
      &-scheduled {
        &:first-child {
          margin-right: 32px;
        }
        h3 {
          font-size: 16px;
          color: $voyager !important;
          font-weight: normal;
          font-style: italic;
        }
        &-timestamp {
          color: $romulan;
        }
      }
    }
  }
  &-details .hk-image-list-header {
    justify-content: space-between;
  }

  &-tasks {
    &-header {
      margin: 64px 0 32px 0;
      display: flex;
      justify-content: space-between;
    }
    h2 {
      font-size: 18px;
      color: $discovery;
      text-transform: uppercase;
    }
  }

  .section {
    border-top: 1px solid $vulcan;
    margin: 48px 0;
    padding-top: 24px;
  }

  // Ant Collapse panels
  .ant-space {
    width: 100%;
  }
  .ant-collapse > .ant-collapse-item {
    border-bottom: 0;
  }
  .ant-collapse {
    background-color: transparent;
    &-item {
      &.ant-collapse-item-active {
        .ant-collapse-header {
          border-radius: 10px 10px 0 0 !important;
          padding: 16px !important;
        }
      }
      .ant-collapse-header {
        display: flex;
        align-items: center;
        height: 72px;
        background-color: $discovery;
        font-size: 18px;
        font-weight: bold;
        text-transform: uppercase;
        color: #fff !important;
        border-radius: $borderRadius !important;
        .ant-collapse-arrow {
          font-size: 18px !important;
          font-weight: bold;
          color: #fff;
        }
        .ant-collapse-extra {
          flex-grow: 1;
          text-align: right;
        }
      }
      .ant-collapse-content {
        padding: 24px;
        border-radius: 0 0 $borderRadius $borderRadius !important;
        background-color: #fff !important;
        border: 1px solid $vulcan;
      }
    }
  }
}

.hk-appointment-assets {
  padding: 24px 0;
}
