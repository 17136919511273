@import 'src/theme/theme';

.hk-login {
  font-family: "Open Sans", sans-serif;
  height: 100vh;
  color: #fff;
  background-color: $romulan;
  text-align: center;
  .hk-logo {
    margin: 156px auto 56px auto;
    width: 260px;
  }
  h1 {
    text-align: left;
    font-weight: normal!important;
    font-size: 40px!important;
    color: #fff;
    padding-bottom: 8px;
    margin-bottom: 0;
  }
  h6 {
    text-align: left;
    font-weight: 600;
    font-size: 16px!important;
    color: $vulcan;
    padding-bottom: 32px;
  }
  .hk-form.ant-form {
    margin: 0 auto 0 auto;
    width: 480px!important;
    .ant-row {
      text-align: left;
      .ant-form-item-label label {
        &::before { display: none; }
        font-weight: bold;
        color: #fff;
        text-align: left;
      }
    }
    .ant-row.ant-form-item .ant-form-item-control .ant-form-item-control-input .ant-form-item-control-input-content {
      text-align: center!important;
    }
  }
  &-error {
    color: $uhura;
  }

  // Autofill styles
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-box-shadow:0 0 0 50px #fff inset; /* Change the color to your own background color */
    background-color: #fff!important;
    border: none;
  }

  .ant-input-password {
    input[type="password"]:-webkit-autofill,
    input[type="password"]:-webkit-autofill:hover,
    input[type="password"]:-webkit-autofill:focus {
      -webkit-box-shadow:0 0 0 50px #fff inset; /* Change the color to your own background color */
      background-color: #fff!important;
      border: none;
    }
  }
}
