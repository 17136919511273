@import 'src/theme/theme';

.hk-appointments {
  position: relative;
  &-breadcrumbs {
    position: absolute;
    top: -36px;
    font-weight: bold;
  }
  &-title {
    font-size: 15px;
    p {
      font-weight: bold;
      margin-bottom: 0;
    }
  }
  .ant-table-row.hk-table-row {
    cursor: pointer;
  }
}
