
@import url('https://fonts.googleapis.com/css?family=Open+Sans');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&display=swap');
@import '_colors.scss';

@function tint($color, $percentage) {
  @return mix(#FFF, $color, $percentage);
}

@function shade($color, $percentage) {
  @return mix(#000, $color, $percentage);
}

$borderRadius: 8px;
$cellPadding: 16px 30px;

body {
  background-color: $bajor!important;
}

h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 900!important;
  font-size: 24px!important;
}

a { color: $spock; }
a:hover {
  color: $enterprise !important;
}

.hk-spinner.ant-spin.ant-spin-spinning {
  position: relative!important;
  display: block;
  margin: 100px auto;
  .ant-spin-dot .ant-spin-dot-item { background-color: $enterprise; }
}

button[type=submit],
button.hk-header-button {
  border: 0;
  height: 48px!important;
  margin-left: 16px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  background: $enterprise!important;
  border-radius: 8px!important;
  box-shadow: 0 6px 20px 0 rgba(13,51,32,0.1);
  &:hover {
    box-shadow: none;
    background: tint($enterprise, 30)!important;
  }
}

button.ant-btn-dangerous {
  background: $uhura!important;
  &:hover {
    box-shadow: none;
    background: tint($uhura, 30)!important;
  }
}

button.hk-medium-button {
  border: 0;
  height: 44px!important;
  padding: 8px 16px;
  font-weight: bold;
  color: #fff;
  background: $kirk!important;
  border-radius: 8px!important;
  box-shadow: 0 4px 16px 0 rgba(13,51,32,0.1);
  &:hover {
    box-shadow: none;
    background: tint($kirk, 30)!important;
  }
}

button.hk-small-button {
  border: 0;
  height: 36px!important;
  padding: 6px 16px;
  font-weight: bold;
  color: #fff;
  background: $scotty!important;
  border-radius: 8px!important;
  box-shadow: 0 2px 8px 0 rgba(13,51,32,0.1);
  &:hover {
    box-shadow: none;
    background: tint($scotty, 30)!important;
  }
  &:disabled {
    background: $klingon!important;
    color: $voyager;
  }
}

button.hk-action-button {
  border: 1px solid $romulan;
  height: 36px!important;
  padding: 6px 16px;
  font-weight: bold;
  color: $romulan;
  border-radius: 8px!important;
  &:hover {
    color: $romulan;
    border: 1px solid $romulan;
    background: $freeman;
  }
}

.ant-switch-checked {
  background-color: $enterprise !important;
}

// Modals

.ant-modal-confirm.hk-delete-confirm-modal {
  width: 800px!important;
  .ant-modal-content {
    border-radius: 10px;
    .ant-modal-close {
      padding: 8px;
      color: #fff;
      .ant-modal-close-x {
        font-size: 20px!important;
      }
    }
    .ant-modal-body {
      padding: 0;
      .anticon.anticon-exclamation-circle {
        display: none;
      }
      .ant-modal-confirm-title {
        background-color: $scotty;
        font-family: 'Open Sans', sans-serif;
        font-size: 18px;
        font-weight: bold;
        color: #fff;
        padding: 24px;
        border-radius: 10px 10px 0 0;
      }
      .ant-modal-confirm-content {
        font-family: 'Open Sans', sans-serif;
        font-size: 18px;
        font-weight: bold;
        padding: 24px;
        text-align: center;
      }
    }
    .ant-modal-confirm-btns {
      padding: 24px;
      float: none;
      text-align: center;
      margin-top: 0;
      .ant-btn {
        width: 180px;
        height: 54px;
        font-family: 'Open Sans', sans-serif;
        font-size: 18px;
        font-weight: bold;
        color: #000;
        margin: 0 8px;
        border-radius: 8px;
        &.ant-btn-primary {
          background-color: $uhura;
          border-color: transparent;
        }
      }
    }
  }
}

// Tables

.ant-table-wrapper {
  margin-top: 40px;
  .ant-table {
    border: 1px solid $vulcan;
    border-radius: $borderRadius !important;
    .ant-table-content {
      .ant-table-thead .ant-table-cell {
        padding: $cellPadding;
        font-size: 16px;
        font-weight: bold;
        color: #fff;
        background-color: $scotty;
        &:first-child { border-top-left-radius: $borderRadius !important; }
        &:last-child { border-top-right-radius: $borderRadius !important; }
      }
      .ant-table-tbody {
        border-bottom-left-radius: $borderRadius !important;
        border-bottom-right-radius: $borderRadius !important;
        .ant-table-placeholder .ant-table-cell { border: none }
        .ant-table-row:last-child .ant-table-cell { border: none }
        .ant-table-cell {
          padding: $cellPadding;
          font-size: 16px;
          color: $scotty;
          border-bottom-color: $vulcan !important;
        }
      }
    }
  }
  .ant-spin-nested-loading {
    .ant-spin .ant-spin-dot .ant-spin-dot-item { background-color: $enterprise; }
    .ant-spin-container.ant-spin-blur .ant-table .ant-table-container .ant-table-content table .ant-table-tbody {
      opacity: 0;
    }
  }
}

// Pagination

.ant-pagination.ant-table-pagination {
  .ant-pagination-item.ant-pagination-item-active {
    border: 0;
    background-color: $enterprise;
    a { color: #fff; }
  }
}

// Form Rows

.ant-row.ant-form-item {
  // Form controls
  .ant-form-item-control-input .ant-form-item-control-input-content {
    .ant-select .ant-select-selector,
    ant-select .ant-select-selector-selection-search,
    .ant-input-password,
    input {
      font-size: 14px!important;
    }
    .ant-input-affix-wrapper.ant-input-password {
      input { padding: 0!important; }
    }
    button.ant-switch {
      margin-top: 4px!important;
    }
  }

  // Required *
  .ant-form-item-required.ant-form-item-required::before {
    color: $uhura;
  }

  .ant-form-item-explain-success {
    color: $uhura!important;
    padding: 4px;
    font-size: 12px!important;
    text-align: right;
  }

  // Error messages
  &.ant-form-item-has-error {
    .ant-form-item-control-input .ant-form-item-control-input-content {
      .ant-select .ant-select-selector,
      .ant-input-password,
      input {
        border-color: $uhura!important;
        box-shadow: none!important;
      }
    }
    .ant-form-item-control .ant-form-item-explain {
      color: $uhura!important;
      padding: 4px;
      font-size: 12px!important;
      text-align: right;
    }
  }
}

.ant-input-group-wrapper.ant-input-search {
  border-top-left-radius: 8px!important;
  border-bottom-left-radius: 8px!important;
  .ant-input-wrapper.ant-input-group {
    .ant-input-affix-wrapper  {
      height: 58px;
      font-size: 24px;
      padding: 8px!important;
      border: 0;
      border-top-left-radius: 8px!important;
      border-bottom-left-radius: 8px!important;
      box-shadow: 0 6px 20px 0 rgba(13,51,32,0.1);
      .ant-input-suffix .anticon.anticon-close-circle.ant-input-clear-icon {
        font-size: 20px;
        margin-right: 8px;
      }
    }
    .ant-input-group-addon {
      button {
        border: 0;
        width: 180px;
        height: 58px;
        font-size: 18px;
        font-weight: bold;
        background-color: $enterprise;
        border-top-right-radius: 8px!important;
        border-bottom-right-radius: 8px!important;
        box-shadow: 0 6px 20px 0 rgba(13,51,32,0.1);
      }
    }
  }
}

.ant-row.ant-form-item {
  .ant-form-item-label > label {
    padding-top: 4px;
    font-size: 16px;
  }
}
