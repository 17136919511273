@import 'src/theme/theme';

.hk-notes {
  padding: 24px 0;
  border-bottom: 1px solid $klingon;
  &-body {
    display: flex;
    align-items: flex-start;
    &-form {
      flex-grow: 1;
      margin-right: 24px;
      &-text {
        font-size: 16px;
        color: $voyager;
        .placholder {
          font-style: italic;
        }
      }
    }
    &-actions {
      display: flex;
      flex-grow: 0;
      flex-direction: column;
      button {
        margin-bottom: 8px;
      }
    }
  }
}