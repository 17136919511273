
// primary
$enterprise: #00D2B7;
$discovery: #1D202B;
$scotty: #353A4D;
$romulan: #1B1C1F;
$klingon: #4A4A4A;
$vulcan: #BDBDBD;

// secondary
$uhura: #F88E8E;
$sulu: #00EDDC;
$kirk: #F6C563;
$spock: #00ACFF;
$mccoy: #00D8F1;
$bajor: #F0F0F0;
$tendi: #C5DC64;

$voyager: #808080;

$freeman: #D9F9F4;
$dvana: #EFF4DE;
$boimler: #FEEEEE;
$rutherford: #FEF7E8;
$mariner: #D9F9FD;
$cerritos: #F5F5F5;
